<template>
  <div>
    <base-alert v-model="hasError" :dismissible="false" :type="alertType.error">
      <span v-html="currentError"></span>
    </base-alert>
    <br />

    <!-- check In Extension selection -->
    <v-select
      :hint="displayMessage()"
      item-text="key"
      item-value="value"
      v-model="checkInExtensionValue"
      :prepend-icon="checkInExtensionIcon"
      :items="getCheckInExtension()"
      :label="labelTitleCheckInAs"
      v-on:change="emitExtension"
      persistent-hint
    >
    </v-select>

    <!-- Check In Option -->
    <check-in-option :check-in-option="checkInOption"></check-in-option>

    <!-- Check progress display  -->
    <v-container v-show="visibleProgress">
      <progress-display> {{ progressText }}</progress-display>
    </v-container>
  </div>
</template>

<script>
// design
import { alertType } from "@/design/components/alert/alertModel";
import { iconFileCheckIn } from "@/design/icon/iconConst";

// model
import {
  checkInCompoundMultipleImageFileExtensions,
  checkInMultipleImageFileExtensions,
  checkInSingleImageFileExtensions,
  extensionName,
  findCheckInMultipleImageFileExtension,
  findCheckInSingleImageFileExtensions
} from "@/model/record/fileModel";

// utils
import { isEmpty } from "@/utils";

// mixins
import { menuItemSettingsMixin } from "@/mixins/shared/base/settings/menuItemSettingsMixin";
import { createCheckInOption } from "@/model/record/checkInModel";
import { isCompoundFile } from "@/services/record/recordService";
import { eventNames } from "@/model/common/events/eventConst";

export default {
  name: "CheckInDialogContext",
  components: {
    BaseAlert: () => import("@/components/shared/base/BaseAlert"),
    CheckInOption: () =>
      import("@/components/shared/core/checkIn/CheckInOption"),
    ProgressDisplay: () =>
      import("@/components/shared/core/progress/ProgressDisplay")
  },
  mixins: [menuItemSettingsMixin],
  data() {
    return {
      extensionName: extensionName,
      alertType: alertType,
      iconFileCheckIn: iconFileCheckIn
    };
  },
  props: {
    record: {
      type: Object,
      required: true
    },
    extension: {
      type: String,
      required: true
    },
    filePageCount: {
      type: Number,
      required: true
    },
    checkInModel: {
      type: Object,
      required: true,
      // Specify default as expected Object
      default: () => ({
        visibleCheckInProgress: false,
        currentError: undefined,
        checkInExtension: {
          key: "As It Is",
          value: this.extension
        }
      })
    },
    checkInOption: {
      type: Object,
      required: true,
      // Specify default as expected Object
      default: () => createCheckInOption()
    }
  },
  computed: {
    checkInExtensionValue: {
      get() {
        return this.checkInModel?.checkInExtension.value;
      },
      set(value) {
        this.checkInModel.checkInExtension.value = value;
      }
    },

    /**
     * compute whether current error exists
     * @return {boolean} true if current error exists
     */
    hasError() {
      return !isEmpty(this.checkInModel?.currentError);
    },

    /**
     * get/set current Error
     */
    currentError: {
      get() {
        return this.checkInModel?.currentError;
      },
      set(value) {
        this.checkInModel.currentError = value;
      }
    },

    /**
     * Get Check In As label
     */
    labelTitleCheckInAs() {
      return "Check In As ";
    },

    /**
     * compute progress Text
     * @return {string} computed progress Text
     */
    progressText() {
      return `Checking In ...`;
    },

    /**
     * compute whether Progress should be visible
     * @return {boolean} true if visible Progress
     */
    visibleProgress() {
      return this.checkInModel?.visibleCheckInProgress ?? false;
    },

    /**
     * compute check In Extension Icon
     * @return {string} computed check In Extension Icon
     */
    checkInExtensionIcon() {
      return (this.filePageCount ?? 0) > 1
        ? findCheckInMultipleImageFileExtension(this.checkInExtensionValue)
            ?.icon
        : findCheckInSingleImageFileExtensions(this.checkInExtensionValue)
            ?.icon;
    }
  },
  methods: {
    /**
     * Get Check In As (extension) dialog Title
     * Overwrites recordDetailActionMixin dialogTitleCheckInAs
     * @param {string} extension file extension
     * @return {string} heck In As (extension) dialog Title
     */
    dialogTitleCheckInAs(extension) {
      console.log(`extension:`, extension);
      console.log(`this.extension:`, this.extension);
      console.log(`this.checkInModel:`, this.checkInModel);
      console.log(`this.checkInOption:`, this.checkInOption);
      return `${this.checkInFileCommandLabel} As ${extension}`;
    },

    /**
     * get Possible Check In Extensions
     * @returns {array}.
     */
    getCheckInExtension() {
      try {
        return this.filePageCount > 1
          ? isCompoundFile(this.record)
            ? checkInCompoundMultipleImageFileExtensions
            : checkInMultipleImageFileExtensions
          : checkInSingleImageFileExtensions;
      } catch (e) {
        this.currentError = e.toString();
      }
    },

    /**
     * Gets Hint Message for select extension if compound document file
     * * @returns {string}.
     */
    displayMessage() {
      return isCompoundFile(this.record) && this.filePageCount > 1
        ? "MultiPage TIFF Not Supported in Compound Document"
        : "";
    },

    /**
     * Emit Selected Extension to Parent
     * @param {string} ext
     */
    emitExtension(ext) {
      console.log(ext);
      this.$emit(eventNames.selectedExtension, ext);
    }
  }
};
</script>
